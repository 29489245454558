/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import FullStory from 'react-fullstory';

import "./style.scss";

const MinimalLayout = ({ children }) => {
    return (
        <>
            {children}
            {CONFIG.env !== 'local' && <FullStory org={CONFIG.fullstory.orgId} />}
        </>
    );
    
}

MinimalLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MinimalLayout;
